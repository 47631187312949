/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  Use Defaults
*/
/*
 *  FWAD base Font size
*/
/*
 * H1
*/
/*
 * header fonts mobile devices
 */
/*
 * header fonts tablets(portrait)
 */
/*
 * header fonts tablets(landscape) and small desktop
 */
/*
 * header fonts large desktop
 */
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*/*
 * body-2
*/
/*
 * body-3
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ar-1
*/
/*/*
 * body-ar-2
*/
/*
 * body-ar-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ru-1
*/
/*/*
 * body-ru-2
*/
/*
 * body-ru-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-zh-1
*/
/*/*
 * body-zh-2
*/
/*
 * body-zh-3
*/
/*
* z-index variables
*/
/*
* form placeholder color variables
*/
/*
 * Background Image
 */
/*
  * Line height font
  */
/*
  * Border Radius
  */
/*
  * Box Sizing
  */
/*
  * Box Shadow
  */
/*
  * Border Radius Separate
  */
/*
  * Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * large screen Desktop (PC's)
  */
/*
  * Wide screen Devices
  */
/*
  * upto Tablet
  */
/*
  * Tablet
  */
/*
  * TabletOnly
  */
/*
  * Mobile
  */
/*
  * Transition
     usage example
       a{
       color: gray;
       @include transition(color .3s ease);
       &:hover {
         color: black;
       }
 }
  */
/*
  * Opacity
  */
/*
  * Clearfix
  */
/*
  * Configure vendor
  */
/*
  * Transform
  */
/**
 *   Use Arrow inside the :after and :before pseudo elements
 *   and change the position as per needed
 *   @include arrows('right', 7px, 7px, #000);
 **/
/**
 *   Background gradient mixin
 **/
/*
  * Flex
  */
/*
 * @ Fonts
 *
*/
/*
 * Base
 * @ Colors
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
.tenant-fwad .jss-form-wrapper .c-form.jss-form {
  margin: 0 auto;
}
.tenant-fwad .jss-form-wrapper .c-form.jss-form fieldset {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-fwad .jss-form-wrapper .c-form.jss-form fieldset {
    margin: 0;
    max-width: 328px;
  }
}
.tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #000000;
  margin-top: 55px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-ar .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-ru .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 42px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
body.lang-zh .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 33px;
    line-height: 39px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .heading-1 h2 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0;
  }
}
.tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .form-label {
  color: #999999;
}
.tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .active .form-label {
  color: #515151;
}
.tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .active textarea {
  padding: 25px 18px 7px;
}
.tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .text-center {
  padding-bottom: 60px;
}
.tenant-fwad .jss-form-wrapper .c-form.jss-form .c-form-wrapper .form-text-area {
  min-height: 180px;
}
.tenant-fwad .jss-form-wrapper .c-form.jss-form .form-element:not(.form-checkbox) .form-label {
  color: #666666;
}
.tenant-fwad .jss-form-wrapper .c-form.jss-form .form-element {
  margin: 20px 0;
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-fwad .jss-form-wrapper .c-form.jss-form .form-element.form-element-salutation {
  width: 60%;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .jss-form-wrapper .c-form.jss-form .form-element.form-element-salutation {
    width: 27.5%;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .jss-form-wrapper .c-form.jss-form .form-element.form-element-left, .tenant-fwad .jss-form-wrapper .c-form.jss-form .form-element.form-element-right {
    width: 49%;
    margin-left: 0;
    margin-right: 1%;
    display: inline-block;
    vertical-align: middle;
  }
  .lang-ar .tenant-fwad .jss-form-wrapper .c-form.jss-form .form-element.form-element-left, .lang-ar .tenant-fwad .jss-form-wrapper .c-form.jss-form .form-element.form-element-right {
    margin-left: 1%;
    margin-right: 0;
  }
  .tenant-fwad .jss-form-wrapper .c-form.jss-form .form-element.form-element-right {
    margin-left: 1%;
    margin-right: 0;
  }
  .lang-ar .tenant-fwad .jss-form-wrapper .c-form.jss-form .form-element.form-element-right {
    margin-left: 0;
    margin-right: 1%;
  }
}
.tenant-fwad .jss-form-wrapper .c-form.jss-form .form-element select {
  font-size: 16px;
}
.tenant-fwad .jss-form-wrapper .c-editorial-grid .w--middle {
  margin: 0;
}
.tenant-fwad .jss-form-wrapper .server-error {
  margin: 20px;
  text-align: center;
}
.tenant-fwad .jss-form-wrapper.B2C-bookDate .disabled:not(.product-selection) {
  opacity: 0.3;
  pointer-events: none;
}
.tenant-fwad .jss-form-wrapper.B2C-bookDate .jss-form .c-my-cart-b2c-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-fwad .jss-form-wrapper.B2C-bookDate .jss-form .c-my-cart-b2c-container {
    margin-bottom: 20px;
  }
}
.tenant-fwad .jss-form-wrapper.B2C-bookDate .jss-form .c-my-cart-b2c-container .checkout-button .login-signup .form-wrapper-buttons-anchor {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 29px;
  color: #333333;
}
.tenant-fwad .jss-form-wrapper.B2C-bookDate .jss-form .c-my-cart-b2c-container .checkout-button .login-signup .form-wrapper-buttons-anchor:after {
  left: 0;
  content: "";
  position: absolute;
  width: 100%;
  border-bottom: 2px solid #333333;
  top: calc(100% - 2px);
}
.lang-ar .tenant-fwad .jss-form-wrapper.B2C-bookDate .jss-form .c-my-cart-b2c-container .checkout-button .login-signup .form-wrapper-buttons-anchor {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: -0.55px;
}
.tenant-fwad .jss-form-wrapper.B2C-bookDate .jss-form .c-my-cart-b2c-container .checkout-button .login-signup .header-bottom {
  padding: 0;
}
.tenant-fwad .jss-form-wrapper.B2C-bookDate .jss-form .c-my-cart-b2c-container .checkout-button .login-signup .header-bottom .c-ticket-tooltip .header-bottom-profile .profile {
  margin-bottom: 6px;
}
.tenant-fwad .jss-form-wrapper.B2C-bookDate .jss-form .jss-form .book-ticket-calendar .react-datepicker__navigation--previous, .tenant-fwad .jss-form-wrapper.B2C-bookDate .jss-form .jss-form .book-ticket-calendar .react-datepicker__navigation--next {
  margin: 0;
}
.tenant-fwad .jss-form-wrapper.B2C-bookDate .jss-form .jss-form .book-ticket-calendar .react-datepicker__navigation--previous:before, .tenant-fwad .jss-form-wrapper.B2C-bookDate .jss-form .jss-form .book-ticket-calendar .react-datepicker__navigation--next:before {
  right: 13px;
}

.lang-ar .jss-form-wrapper .c-form.jss-form .form-element select {
  font-size: 14px;
  font-size: 0.875rem;
}